import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravabemc2024newyork',
  name: 'Strava BEMC 2024: New York',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm11rn06g01ex01pm4s5v8zq3',
  cssStyles,
  palette,
}
