import axios from 'axios'

const { REACT_APP_STRAVA_CLIENT_SECRET, REACT_APP_STRAVA_CLIENT_ID } =
  process.env

export const STRAVA_AUTH_TOKEN_ENDPOINT =
  'https://www.strava.com/oauth/authorize'
export const STRAVA_ACCESS_TOKEN_ENDPOINT = 'https://www.strava.com/oauth/token'

export const STRAVA_ATHLETE_API = 'https://www.strava.com/api/v3/athlete'
export const STRAVA_ACTIVITY_API =
  'https://www.strava.com/api/v3/activities/{id}'
export const STRAVA_ACTIVITY_STREAMS_API =
  'https://www.strava.com/api/v3/activities/{id}/streams'
export const STRAVA_ROUTE_API = 'https://www.strava.com/api/v3/routes/{id}'
export const STRAVA_ROUTE_STREAMS_API =
  'https://www.strava.com/api/v3/routes/{id}/streams'

export const STRAVA_STREAM_SETS = [
  'latlng',
  'altitude',
  // 'time',
  // 'distance',
  // 'velocity_smooth',
  // 'heartrate',
  // 'cadence',
  // 'watts',
  // 'temp',
  // 'moving',
  // 'grade_smooth',
]

export const stravaStreamResolution = {
  LOW: 'low', // 100 points
  MEDIUM: 'medium', // 1,000 points
  HIGH: 'high', // 10,000 points
}

export const stravaStreamSeriesType = {
  TIME: 'time',
  DISTANCE: 'distance',
}

export const STRAVA_STREAM_PARAMS = {
  key_by_type: true,
  series_type: stravaStreamSeriesType.DISTANCE,
  resolution: stravaStreamResolution.MEDIUM,
  keys: STRAVA_STREAM_SETS.join(','),
}

// -----------------------------------------------------------------------------
// TOKENS
// -----------------------------------------------------------------------------

/**
 * The API request for the access token
 *
 */
export function* apiGetStravaAccessToken(authToken) {
  const { data } = yield axios.post(STRAVA_ACCESS_TOKEN_ENDPOINT, {
    code: authToken,
    grant_type: 'authorization_code',
    client_id: REACT_APP_STRAVA_CLIENT_ID,
    client_secret: REACT_APP_STRAVA_CLIENT_SECRET,
  })
  return data
}

/**
 * The API request to refresh the access token
 *
 */
export function* apiRefreshStravaAccessToken(refreshToken) {
  const { data } = yield axios.post(STRAVA_ACCESS_TOKEN_ENDPOINT, {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    client_id: REACT_APP_STRAVA_CLIENT_ID,
    client_secret: REACT_APP_STRAVA_CLIENT_SECRET,
  })
  return data
}

// -----------------------------------------------------------------------------
// ATHLETE
// -----------------------------------------------------------------------------
export function* apiGetStravaAthlete(accessToken) {
  const { data } = yield axios
    .get(STRAVA_ATHLETE_API, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(caughtStravaApiError())
  return data
}

// -----------------------------------------------------------------------------
// ACTIVITIES
// -----------------------------------------------------------------------------

/**
 * API interface for calling the Strava endpoint to fetch activities
 *
 */
export function* apiGetStravaActivity(id = null, accessToken = null) {
  return yield axios
    .get(STRAVA_ACTIVITY_API.replace('{id}', id), {
      params: {
        include_all_efforts: true,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(caughtStravaApiError(id))
}

/**
 * API Interface for fetching activity streams from Strava, which contains
 * a lot more route information
 *
 */
export function* apiGetStravaActivityStream(id = null, accessToken = null) {
  const payload = yield axios
    .get(STRAVA_ACTIVITY_STREAMS_API.replace('{id}', id), {
      params: STRAVA_STREAM_PARAMS,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(caughtStravaApiError(id))
  if (payload.data) payload.data.id = id
  return payload
}

// -----------------------------------------------------------------------------
// ROUTES
// -----------------------------------------------------------------------------

/**
 * API Interface for fetching a Strava route by ID
 *
 */
export function* apiGetStravaRoute(id = null, accessToken = null) {
  return yield axios
    .get(STRAVA_ROUTE_API.replace('{id}', id), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(caughtStravaApiError(id))
}

/**
 * API Interface for fetching a Strava route stream by ID
 *
 */
export function* apiGetStravaRouteStream(id = null, accessToken = null) {
  const payload = yield axios
    .get(STRAVA_ROUTE_STREAMS_API.replace('{id}', id), {
      params: STRAVA_STREAM_PARAMS,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(caughtStravaApiError(id))
  if (payload.data) payload.data.id = id
  return payload
}

// -----------------------------------------------------------------------------
// UTILITIES
// -----------------------------------------------------------------------------

/**
 * Returns a caught API error that is easier to work with.
 *
 */
export const caughtStravaApiError = (id) => (error) => ({
  ...error.response,
  errors: {
    id,
    ...error.response.data,
  },
  data: null,
})
