import { css } from 'styled-components'

import { fontGroup } from '../../styles'
import MapboxMap from '../../../../components/MapboxMap'
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo'
import { PrintLabel } from '../../../../components/PrintLabel'
import { PrintTitle } from '../../../../components/PrintTitle'

export default ({ theme }) => css`
  ${fontGroup('montserratAlternateGothic')}
  ${PrintTitle} {
    margin-bottom: 0.2em;
  }

  ${LogoCredits},
  ${PoweredByStrava} {
    color: ${theme.primaryColor};
    opacity: 1;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #6f9260;
      opacity: 1;
    }
  }

  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 32rem;
    height: 50rem;
    position: absolute;
    top: 14rem;
    left: 14rem;
    background: transparent no-repeat center center;
    background-size: cover;
    border-radius: 1.5rem;
    border: 1px solid #000000;
  }

  .mapboxgl-ctrl-bottom-left {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
  }
`
