import Immutable from 'seamless-immutable'

import themes from './themes'

import heatherImg from './themes/heather/thumb.png'
import reddeadImg from './themes/reddead/thumb.png'
import lingerieImg from './themes/lingerie/thumb.png'
import blueprintImg from './themes/blueprint/thumb.png'
import moonrakerImg from './themes/moonraker/thumb.png'
import swampthingImg from './themes/swampthing/thumb.png'
import theclassicImg from './themes/theclassic/thumb.png'
import mintymilesImg from './themes/mintymiles/thumb.png'
import paperchaseImg from './themes/paperchase/thumb.png'
import coppermineImg from './themes/coppermine/thumb.png'
import crimsonrideImg from './themes/crimsonride/thumb.png'
import americanrustImg from './themes/americanrust/thumb.png'
import outlines1Img from './themes/outlines1/thumb.png'
import outlines2Img from './themes/outlines2/thumb.png'

// import thirteenpeaksonedayImg from './themes/thirteenpeaksoneday/thumb.png';
// import thirteenpeakstwodayImg from './themes/thirteenpeakstwoday/thumb.png';
// import thirteenpeaksmultidayImg from './themes/thirteenpeaksmultiday/thumb.png';

// Theme options
// We need to be able to specify specific colours here since not all
// theme colours have enough contrast against a white background

// -------------------------------------------------------------------------
// PT THEME OPTIONS
// -------------------------------------------------------------------------
export const paperTrailsThemes = [
  {
    label: themes.outlines2.name,
    value: themes.outlines2.id,
    image: outlines2Img,
    color: '#ED4512',
  },
  {
    label: themes.outlines1.name,
    value: themes.outlines1.id,
    image: outlines1Img,
    color: '#299FBC',
  },
  {
    label: themes.heather.name,
    value: themes.heather.id,
    image: heatherImg,
    color: '#525030',
  },
  {
    label: themes.theclassic.name,
    value: themes.theclassic.id,
    image: theclassicImg,
    color: '#6d3b2c',
  },
  {
    label: themes.coppermine.name,
    value: themes.coppermine.id,
    image: coppermineImg,
    color: '#ff6b36',
  },
  {
    label: themes.moonraker.name,
    value: themes.moonraker.id,
    image: moonrakerImg,
    color: '#26303d',
  },
  {
    label: themes.crimsonride.name,
    value: themes.crimsonride.id,
    image: crimsonrideImg,
    color: '#ff4700',
  },
  {
    label: themes.mintymiles.name,
    value: themes.mintymiles.id,
    image: mintymilesImg,
    color: '#51c6ac',
  },
  {
    label: themes.blueprint.name,
    value: themes.blueprint.id,
    image: blueprintImg,
    color: '#405ebf',
  },
  {
    label: themes.swampthing.name,
    value: themes.swampthing.id,
    image: swampthingImg,
    color: '#67793c',
  },
  {
    label: themes.reddead.name,
    value: themes.reddead.id,
    image: reddeadImg,
    color: '#ec0000',
  },
  {
    label: themes.lingerie.name,
    value: themes.lingerie.id,
    image: lingerieImg,
    color: '#d56cf7',
  },
  {
    label: themes.americanrust.name,
    value: themes.americanrust.id,
    image: americanrustImg,
    color: '#df5d3d',
  },
  {
    label: themes.paperchase.name,
    value: themes.paperchase.id,
    image: paperchaseImg,
    color: '#7b7b7b',
  },
]

// -------------------------------------------------------------------------
// AFFILIATE THEME OPTIONS
// -------------------------------------------------------------------------
export const eventThemes = [
  // {
  //   label: themes.thirteenpeaksoneday.name,
  //   value: themes.thirteenpeaksoneday.id,
  //   image: thirteenpeaksonedayImg,
  //   color: '#bea15c',
  // }, {
  //   label: themes.thirteenpeakstwoday.name,
  //   value: themes.thirteenpeakstwoday.id,
  //   image: thirteenpeakstwodayImg,
  //   color: '#aaaaaa',
  // }, {
  //   label: themes.thirteenpeaksmultiday.name,
  //   value: themes.thirteenpeaksmultiday.id,
  //   image: thirteenpeaksmultidayImg,
  //   color: '#cd8550',
  // },

  // GLORIOUS GRAVEL
  // ---------------------------------------------------------------------------
  {
    label: themes.ggdorsetgravelx.name,
    value: themes.ggdorsetgravelx.id,
    image: '', // not used
    color: '#cb3817',
  },
  {
    label: themes.ggpeakdistrictgravelx.name,
    value: themes.ggpeakdistrictgravelx.id,
    image: '', // not used
    color: '#cb3817',
  },
  {
    label: themes.ggsherwoodgravelx.name,
    value: themes.ggsherwoodgravelx.id,
    image: '', // not used
    color: '#cb3817',
  },
  {
    label: themes.ggsuffolkgravelx.name,
    value: themes.ggsuffolkgravelx.id,
    image: '', // not used
    color: '#cb3817',
  },
  {
    label: themes.ggnorthwalesgravelx.name,
    value: themes.ggnorthwalesgravelx.id,
    image: '', // not used
    color: '#cb3817',
  },

  // PAN CELTIC
  // ---------------------------------------------------------------------------
  {
    label: themes.pcGravelRally2022Full.name,
    value: themes.pcGravelRally2022Full.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcGravelRally2022Short.name,
    value: themes.pcGravelRally2022Short.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2022Full.name,
    value: themes.pcUltra2022Full.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2022Short.name,
    value: themes.pcUltra2022Short.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2022Mixed.name,
    value: themes.pcUltra2022Mixed.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2023Full.name,
    value: themes.pcUltra2023Full.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2023Short.name,
    value: themes.pcUltra2023Short.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.pcUltra2023Mixed.name,
    value: themes.pcUltra2023Mixed.id,
    image: '', // not used
    color: '#3b8875',
  },

  // GREAT BRITISH DIVIDE
  {
    label: themes.gbdivide2021.name,
    value: themes.gbdivide2021.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.gbdivide2022.name,
    value: themes.gbdivide2022.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.gbdivide2023full.name,
    value: themes.gbdivide2023full.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.gbdivide2023full.name,
    value: themes.gbdivide2023short.id,
    image: '', // not used
    color: '#3b8875',
  },

  // TRIBE X MAVERICK
  {
    label: themes.tribeXMaverick2022Ultra,
    value: themes.tribeXMaverick2022Ultra.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.tribeXMaverick2022Marathon,
    value: themes.tribeXMaverick2022Marathon.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.tribeXMaverick2022Half,
    value: themes.tribeXMaverick2022Half.id,
    image: '', // not used
    color: '#3b8875',
  },
  {
    label: themes.tribeXMaverick2022Short,
    value: themes.tribeXMaverick2022Short.id,
    image: '', // not used
    color: '#3b8875',
  },

  // STRAVA BEMC 2024
  {
    label: themes.stravabemc2024light.name,
    value: themes.stravabemc2024light.id,
    image: '',
    color: '#cb3817',
  },
  {
    label: themes.stravabemc2024dark.name,
    value: themes.stravabemc2024dark.id,
    image: '',
    color: '#cb3817',
  },
  {
    label: themes.stravabemc2024orange.name,
    value: themes.stravabemc2024orange.id,
    image: '',
    color: '#cb3817',
  },
  {
    label: themes.stravabemc2024berlin.name,
    value: themes.stravabemc2024berlin.id,
    image: '',
    color: '#cb3817',
  },
  {
    label: themes.stravabemc2024chicago.name,
    value: themes.stravabemc2024chicago.id,
    image: '',
    color: '#cb3817',
  },
  {
    label: themes.stravabemc2024newyork.name,
    value: themes.stravabemc2024newyork.id,
    image: '',
    color: '#cb3817',
  },
]

// we need this for WithActivities/model, but can't import directly from
// WithThemes/themes/index.js because failing tests with import race
export const themesEnum = []
  .concat(paperTrailsThemes, eventThemes)
  .reduce((acc, cur) => {
    acc[cur.value] = cur.value
    return acc
  }, {})

export const eventThemeNames = eventThemes.map((theme) => theme.value)

export const initialState = Immutable({
  // All themes injected here
  themes,

  // The default selected theme name
  themeName: paperTrailsThemes[0].value,

  options: {
    themes: paperTrailsThemes,
    eventThemes,
  },
})
