import * as Constants from './constants'

export const setOrderInProgress = (inProgress) => ({
  type: Constants.SET_ORDER_IN_PROGRESS,
  inProgress,
})

export const createZeroCostOrder = (user) => ({
  type: Constants.CREATE_ZERO_COST_ORDER_REQUEST,
  user,
})

export const createStripeOrder = (paymentMethodId, user) => ({
  type: Constants.CREATE_STRIPE_ORDER_REQUEST,
  paymentMethodId,
  user,
})

export const confirmStripeOrder = (paymentIntentId, user) => ({
  type: Constants.CONFIRM_STRIPE_ORDER_REQUEST,
  paymentIntentId,
  user,
})

export const setStripeActionRequired = (
  stripeActionRequired = false,
  intentSecret,
  orderId,
) => ({
  type: Constants.SET_STRIPE_ACTION_REQUIRED,
  stripeActionRequired,
  intentSecret,
  orderId,
})

export const createPaypalOrder = (paypalOrderId, coupon) => ({
  type: Constants.CREATE_PAYPAL_ORDER_REQUEST,
  paypalOrderId,
  coupon,
})

export const getPrintOrder = (printId) => ({
  type: Constants.GET_PRINT_ORDER_REQUEST,
  printId,
})
