import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import ScriptLoader from 'react-script-loader-hoc'
import { Elements } from '@stripe/react-stripe-js'

import * as Actions from './actions'
import * as Selectors from './selectors'
import { STRIPE_SRC } from './constants'

import font400 from '../../fonts/apercu-400-woff'
import font500 from '../../fonts/apercu-500-woff'

const { REACT_APP_STRIPE_API_KEY } = process.env

const StripeHoc = (WrappedComponent) =>
  // eslint-disable-next-line
  class WithHoc extends React.Component {
    static displayName = `withStripeHoc(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`

    static propTypes = {
      stripeToken: PropTypes.shape({}),
      scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
      newStripeToken: PropTypes.func.isRequired,
    }

    static defaultProps = {
      stripeToken: undefined,
    }

    componentWillReceiveProps(nextProps) {
      const noToken = !nextProps.stripeToken
      const scriptLoaded = nextProps.scriptsLoadedSuccessfully
      if (noToken && scriptLoaded) {
        this.props.newStripeToken(window.Stripe(REACT_APP_STRIPE_API_KEY))
      }
    }

    getFonts() {
      // https://stripe.com/docs/stripe-js/reference#elements-options
      return [
        {
          family: 'Apercu',
          src: `${font400}`,
          weight: 400,
        },
        {
          family: 'Apercu',
          src: `${font500}`,
          weight: 500,
        },
      ]
    }

    render() {
      if (!this.props.stripeToken) return <WrappedComponent {...this.props} />
      return (
        <Elements
          stripe={this.props.stripeToken}
          fonts={this.getFonts()}
          options={{
            mode: 'setup',
            currency: 'gbp',
            paymentMethodTypes: ['card'],
            paymentMethodCreation: 'manual',
            setupFutureUsage: 'off_session',
          }}
        >
          <WrappedComponent {...this.props} />
        </Elements>
      )
    }
  }

export const MakeWithStripeHoc = (WrappedComponent) =>
  new StripeHoc(WrappedComponent)

export const WithStripeHoc = () => (WrappedComponent) => {
  const WithStripe = MakeWithStripeHoc(WrappedComponent)

  const mapStateToProps = createStructuredSelector({
    stripeToken: Selectors.tokenSelector,
  })

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        newStripeToken: Actions.newStripeToken,
      },
      dispatch,
    )

  const withConnect = connect(mapStateToProps, mapDispatchToProps)

  const composed = compose(ScriptLoader(STRIPE_SRC), withConnect)(WithStripe)

  return composed
}

export default WithStripeHoc
