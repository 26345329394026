import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Hero from '../../images/bemc-strava.webp'
import HeroMobile from '../../images/strava-bemc-mobile.jpg'
import { STRAVA_BEMC_2024 } from '../../routes'

const Container = styled.div`
    width: 100%;
    max-width: 150rem;
    margin: auto;
    padding-left: 6rem;
    padding-right: 6rem;
    max-width: calc(122.6rem + 6rem + 6rem);
    margin-top: 34px;
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 8rem;
    padding-bottom: 8rem;
    @media(max-width: 768px) {
      margin-top: 0;
      padding-bottom: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    h2 {
      margin-top: 2rem;
      font-size: 50px;
      line-height: 64px;
      max-width: 986px;
      margin-bottom: 1rem;
      @media(max-width: 768px) {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 0;
      }
    }
    p {
      font-size: 34px;
      line-height: 44px;
      max-width: 693px;
      @media(max-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    a.button {
      display: block;
      text-decoration: none;
      background-color: #FF3801;
      color: #fff;
      border-radius: 12px;
      border: none;
      font-size: 26px;
      line-height: 50px;
      outline: 0;
      padding: 1.5rem 8rem;
      text-transform: uppercase;
      text-align: center;
      @media(max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
      }
    }
    div.flex {
      display: flex;
      margin-top: 4rem;
      column-gap: 100px;
      @media(max-width: 768px) {
        flex-direction: column;
      }
    }
`

const Desktop = styled.div`
  display: block;
  @media(max-width: 768px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
`

const HomeStrava = () => {
  return (
    <Container>
      <Desktop>
        <img src={Hero} alt="" />
      </Desktop>
      <Mobile>
        <img src={HeroMobile} alt="" />
      </Mobile>
      <h2>
        Join the Best Efforts Marathon Challenge powered by adidas and chase your best (or first) marathon PR.
      </h2>
      <div className="flex">
        <p>Record your best time on Strava. Annual Strava subscribers with a top 3 effort earn a free custom print to memorialize the route, the stats and all the sweat that got them to the finish line.</p>
        <span><RouterLink to={STRAVA_BEMC_2024} className="button">Learn More</RouterLink></span>
      </div>
    </Container>
  )
}

export default HomeStrava
