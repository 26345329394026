import { css } from 'styled-components'

import { fontGroup } from '../../styles'
import MapboxMap from '../../../../components/MapboxMap'
import {
  LogoCredits,
  PoweredByStrava,
  StravaAdidas,
} from '../../../../components/Logo'
import {
  PrintLabel,
  PrintLabelTitle,
  PrintLabelValue,
} from '../../../../components/PrintLabel'
import {
  PrintSecondaryTitle,
  PrintTitle,
} from '../../../../components/PrintTitle'
import { Attribution } from '../../../../components/Attribution'

export const badgeStyles = css`
  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 31rem;
    height: 33.5rem;
    position: absolute;
    top: 7rem;
    left: 8rem;
    background: transparent no-repeat center center;
    background-size: cover;
  }
`

export default (theme) => css`
  ${fontGroup('terminaAlternateGothic')(theme)}

  ${PrintTitle} {
    font-size: 2.5rem;
    margin-bottom: 0.2em;
    font-weight: 700;
  }

  ${PrintSecondaryTitle} {
    font-size: 2.1rem;
    text-transform: uppercase;
  }

  ${PrintLabelValue} {
    font-size: 1.6rem;
    text-transform: initial;
    font-weight: 700;
  }

  ${PrintLabelTitle} {
    text-transform: uppercase;
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.5;
  }

  ${LogoCredits},
  ${PoweredByStrava},
  ${StravaAdidas} {
    color: #242428;
    opacity: 1;
  }

  ${Attribution} {
    opacity: 0.3;
    color: #242428;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #fc5200;
      opacity: 0.6;
    }
  }

  ${badgeStyles}
`
