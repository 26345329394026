/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import _debounce from 'lodash.debounce'

import * as SessionConstants from './hocs/WithSession/constants'
import { createToast } from './hocs/WithToaster/actions'
import configureStore from './configureStore'
import * as cache from './cache'
import App from './containers/App'
import Generator from './containers/Generator'

import { APP_INIT } from './sagas'
import * as routes from './routes'

import GlobalStyles from './styles'

const {
  REACT_APP_DEBUG_SIMULATE_ORDER,
  REACT_APP_DEBUG_SAVE_CONFIG,
  BASE_PATH,
} = process.env

const baseTag = document.getElementsByTagName('base')[0]
const baseHref = baseTag ? baseTag.getAttribute('href') : undefined
const history = createBrowserHistory({
  basename: baseHref || BASE_PATH || routes.HOME,
})

if (REACT_APP_DEBUG_SIMULATE_ORDER || REACT_APP_DEBUG_SAVE_CONFIG) {
  console.group('Debug')
  if (REACT_APP_DEBUG_SIMULATE_ORDER) console.info('* order simulation enabled')
  if (REACT_APP_DEBUG_SAVE_CONFIG) console.info('* save config button enabled')
  console.groupEnd()
}

const cachedStore = cache.load()
const store = configureStore(cachedStore, history)

store.dispatch({ type: APP_INIT })
const { pathname } = window.location
if (
  !pathname.match(routes.STRAVA_AUTH) &&
  !pathname.match(routes.LINKS) &&
  navigator.userAgent !== 'ReactSnap'
) {
  // store.dispatch(
  //   createToast(
  //     `'Tis the season! Please ensure your orders are placed no later than the first week of December to avoid gifts not arriving on time! 🎄🎁`,
  //     // `We're off on our own adventures between the 17th and 23rd Sept. We'll process all orders from Monday the 25th as usual. 🏝️`,
  //     undefined,
  //     'primary',
  //   ),
  // )
}

const onFirstTouch = () => {
  window.removeEventListener('touchstart', onFirstTouch, false)
  document.documentElement.classList.add('has-touch')
  store.dispatch({
    type: SessionConstants.SET_HAS_TOUCH,
    hasTouch: true,
  })
}

window.addEventListener('touchstart', onFirstTouch, false)

store.subscribe(
  _debounce(() => {
    cache.save(store.getState())
  }, 500),
)

const Root = () => (
  <Provider store={store}>
    <React.Fragment>
      <GlobalStyles />
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact
            path={`${routes.GENERATE}/:${routes.GENERATE_UUID}`}
            component={Generator}
          />
          <Route path={routes.HOME} component={App} />
        </Switch>
      </ConnectedRouter>
    </React.Fragment>
  </Provider>
)

function renderApp() {
  const rootEl = document.getElementById('root')
  const isCreate = /create/gi.test(window.location.href)
  if (!isCreate && rootEl.hasChildNodes()) {
    hydrate(<Root />, rootEl)
  } else {
    render(<Root />, rootEl)
  }
}

renderApp()
