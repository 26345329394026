import * as Constants from './constants'

export const getStravaAuthToken = () => ({
  type: Constants.GET_STRAVA_AUTH_TOKEN_REQUEST,
})

export const setStravaAuthToken = (authToken) => ({
  type: Constants.SET_STRAVA_AUTH_TOKEN,
  authToken,
})

export const getStravaAccessToken = (authToken) => ({
  type: Constants.GET_STRAVA_ACCESS_TOKEN_REQUEST,
  authToken,
})

export const setStravaAccessTokens = (
  accessToken,
  refreshToken,
  accessTokenExpires,
) => ({
  type: Constants.SET_STRAVA_ACCESS_TOKENS,
  accessToken,
  refreshToken,
  accessTokenExpires,
})

export const setStravaAthleteProfile = (athlete) => ({
  type: Constants.SET_STRAVA_ATHLETE_PROFILE,
  athlete,
})

export const refreshStravaAccessToken = (refreshToken) => ({
  type: Constants.REFRESH_STRAVA_ACCESS_TOKEN_REQUEST,
  refreshToken,
})

export const getShippingAndProducts = () => ({
  type: Constants.GET_SHIPPING_AND_PRODUCTS_REQUEST,
})

export const setShippingAndProducts = (shipping, products) => ({
  type: Constants.SET_SHIPPING_AND_PRODUCTS,
  shipping,
  products,
})

export const getProducts = (couponId) => ({
  type: Constants.GET_PRODUCTS_REQUEST,
  couponId,
})

export const setProducts = (products) => ({
  type: Constants.SET_PRODUCTS,
  products,
})

export const getCoupon = (couponId, printProductId) => ({
  type: Constants.GET_COUPON_REQUEST,
  couponId,
  printProductId,
})

export const setCouponCheckInProgress = (inProgress) => ({
  type: Constants.SET_COUPON_CHECK_IN_PROGRESS,
  inProgress,
})

export const setCouponId = (couponId) => ({
  type: Constants.SET_COUPON_ID,
  couponId,
})

export const acceptCookies = () => ({
  type: Constants.ACCEPT_COOKIES,
})

export const setHasTouch = (hasTouch) => ({
  type: Constants.SET_HAS_TOUCH,
  hasTouch,
})
