/* istanbul ignore file */
import { css } from 'styled-components'

import { loadTypekitThemeSetFonts, loadGoogleFonts } from '../../utils/fonts'

import { PrintTitle, PrintSecondaryTitle } from '../../components/PrintTitle'
import {
  PrintLabelTitle,
  PrintLabelValue,
  PrintLabel,
} from '../../components/PrintLabel'

import themes from './themes'

export const withPrintTheme = (props) => props.theme

export const generalThemeStyles = (props) => {
  const theme = withPrintTheme(props)
  return css`
    background-color: ${theme.backgroundColor};
    color: ${theme.primaryColor};

    ${PrintSecondaryTitle} {
      color: ${theme.secondaryColor};
    }

    ${PrintLabelTitle} {
      color: ${theme.secondaryColor};
    }
  `
}

/**
 * Theme styles are loaded in at Print level and can only target this
 * React node or below.
 * TESTME: move to utils file
 */
export const specificThemeStyles = (props) => {
  const theme = withPrintTheme(props)
  const themeData = themes[theme.themeName]
  if (!themeData) return null
  return themeData.cssStyles(theme)
}

// -----------------------------------------------------------------------------
// FONT GROUPS
// -----------------------------------------------------------------------------

/**
 * Load a font group by name
 *
 * @param {string} name
 * @param {object} theme curried
 */
export const fontGroup = (name) => (theme) =>
  ({
    terminaMonarcha: terminaMonarchaFontGroup,
    terminaAlternateGothic: terminaAlternateGothicFontGroup,
    circeAlternateGothic: circeAlternateGothicFontGroup,
    montserratAlternateGothic: montserratAlternateGothicFontGroup,
    rubikMonoAlternateGothic: rubikMonoAlternateGothicFontGroup,
    promptCirce: promptCirceFontGroup,
    urwDin: urwDinAlternateGothicFontGroup,
    azoCirceAlternateGothic: azoCirceAlternateGothicFontGroup,
    interstateCondensedAlternateGothic:
      interstateCondensedAlternateGothicFontGroup,
    interAll: interAllFontGroup,
    montserratAll: montserratAllFontGroup,
  }[name](theme))

/**
 * Font Group: Termina & Monarcha
 *
 */
export const terminaMonarchaFontGroup = (theme) => {
  const [termina, monarcha] = loadTypekitThemeSetFonts([
    'termina:700',
    'monarcha:600i',
  ])
  return css`
    ${PrintTitle} {
      text-transform: uppercase;
      font-size: 28px;
      ${termina.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 20px;
      margin-top: 2px;
      ${monarcha.styles}
    }

    ${PrintLabelValue} {
      font-size: 16px;
      letter-spacing: 0.8px;
      ${termina.styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.6rem;
      ${monarcha.styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.6;
      }
    }
  `
}

/**
 * Font Group: Termina & Alternate Gothic
 *
 */
export const terminaAlternateGothicFontGroup = (theme) => {
  const [termina, gothic] = loadTypekitThemeSetFonts([
    'termina:700',
    'alternate-gothic-no-3-d',
  ])
  return css`
    ${PrintTitle} {
      text-transform: uppercase;
      font-size: 28px;
      ${termina.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 20px;
      margin-top: 2px;
      ${gothic.styles}
    }

    ${PrintLabelValue} {
      font-size: 16px;
      letter-spacing: 0.8px;
      ${termina.styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.6rem;
      ${gothic.styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.6;
      }
    }
  `
}

/**
 * Font Group: Circe & Alternate Gothic
 *
 */
export const circeAlternateGothicFontGroup = (theme) => {
  const [circe, altGothic] = loadTypekitThemeSetFonts([
    'circe:800',
    'alternate-gothic-no-3-d',
  ])

  return css`
    ${PrintTitle} {
      text-transform: uppercase;
      font-size: 3.6rem;
      letter-spacing: 2.4px;
      font-weight: 800;
      ${circe.styles};
    }

    ${PrintSecondaryTitle} {
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 0.5px;
      ${altGothic.styles}
    }

    ${PrintLabelValue} {
      font-size: 2rem;
      letter-spacing: 1.2px;
      ${circe.styles};
    }

    ${PrintLabelTitle} {
      text-transform: uppercase;
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      ${altGothic.styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.2;
      }
    }
  `
}

export const montserratAlternateGothicFontGroup = (theme) => {
  const [montserrat] = loadGoogleFonts(['Montserrat:800', 'Montserrat:600'])
  const [altGothic] = loadTypekitThemeSetFonts(['alternate-gothic-no-3-d'])

  return css`
    ${PrintTitle} {
      font-size: 3.6rem;
      text-transform: uppercase;
      ${montserrat.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 2.2rem;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      ${altGothic.styles};
    }

    ${PrintLabelValue} {
      font-size: 1.8rem;
      text-transform: uppercase;
      ${montserrat.styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      ${altGothic.styles};
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.8;
      }
    }
  `
}

export const montserratAllFontGroup = (theme) => {
  const [montserratExtra, montserratSemi] = loadGoogleFonts([
    'Montserrat:800',
    'Montserrat:600',
  ])

  return css`
    ${PrintTitle} {
      font-size: 3rem;
      text-transform: uppercase;
      ${montserratExtra.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 1.5rem;
      text-transform: uppercase;
      ${montserratSemi.styles};
    }

    ${PrintLabelValue} {
      font-size: 1.8rem;
      ${montserratExtra.styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.6rem;
      text-transform: uppercase;
      ${montserratSemi.styles};
    }
  `
}

/**
 * Font Group: Rubik Mono One & Alternate Gothic
 *
 */
export const rubikMonoAlternateGothicFontGroup = (theme) => {
  const [rubikMono] = loadGoogleFonts(['Rubik Mono One'])
  const [altGothic] = loadTypekitThemeSetFonts(['alternate-gothic-no-3-d'])

  return css`
    ${PrintTitle} {
      font-size: 3.6rem;
      text-transform: uppercase;
      ${rubikMono.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 2.2rem;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      ${altGothic.styles};
    }

    ${PrintLabelValue} {
      font-size: 1.8rem;
      text-transform: uppercase;
      ${rubikMono.styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      ${altGothic.styles};
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.8;
      }
    }
  `
}

/**
 * Font Group: Prompt & Circe
 *
 */
export const promptCirceFontGroup = (theme) => {
  const [prompt] = loadGoogleFonts(['Prompt:800'])
  const [circe] = loadTypekitThemeSetFonts(['circe:700'])

  return css`
    ${PrintTitle} {
      font-size: 36px;
      letter-spacing: 2px;
      text-transform: uppercase;
      ${prompt.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 22px;
      letter-spacing: 0.2px;
      margin-top: 3px;
      ${circe.styles};
    }

    ${PrintLabelValue} {
      font-size: 20px;
      letter-spacing: 2px;
      ${prompt.styles};
    }

    ${PrintLabelTitle} {
      font-size: 18px;
      letter-spacing: 0.2px;
      ${circe.styles};
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.8;
      }
    }
  `
}

/**
 * Font Group: URW DIN & Alternate Gothic
 *
 */
export const urwDinAlternateGothicFontGroup = (theme) => {
  const [uwrDin, altGothic] = loadTypekitThemeSetFonts([
    'urw-din:500',
    'alternate-gothic-no-3-d',
  ])

  return css`
    ${PrintTitle} {
      font-size: 36px;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      ${uwrDin.styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 20px;
      letter-spacing: 0.4px;
      ${altGothic.styles};
    }

    ${PrintLabelValue} {
      font-size: 20px;
      letter-spacing: 1px;
      ${uwrDin.styles};
    }

    ${PrintLabelTitle} {
      font-size: 14px;
      letter-spacing: 0.6px;
      ${altGothic.styles};
      margin-top: 4px;
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.8;
      }
    }
  `
}

/**
 * Font Group: Azo Sans & Circe & Alternate Gothic
 *
 */
export const azoCirceAlternateGothicFontGroup = (theme) => {
  const [azoSans, circe, altGothic] = loadTypekitThemeSetFonts([
    'azo-sans-uber:400',
    'circe:700',
    'alternate-gothic-no-3-d',
  ])

  return css`
    ${PrintTitle} {
      font-size: 3.6rem;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      ${azoSans.styles}
    }

    ${PrintSecondaryTitle} {
      font-size: 2rem;
      ${altGothic.styles}
    }

    ${PrintLabelValue} {
      font-size: 2rem;
      font-weight: 800;
      ${circe.styles}
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      ${altGothic.styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.2;
      }
    }
  `
}

/**
 * Font Group: Interstate Condensed & Alternate Gothic
 *
 */
export const interstateCondensedAlternateGothicFontGroup = (theme) => {
  const [interstate, altGothic] = loadTypekitThemeSetFonts([
    'interstate-condensed:800',
    'alternate-gothic-no-3-d',
  ])
  return css`
    ${PrintTitle} {
      font-size: 4rem;
      text-transform: uppercase;
      ${interstate.styles}
    }

    ${PrintSecondaryTitle} {
      font-size: 2rem;
      text-transform: uppercase;
      ${altGothic.styles}
    }

    ${PrintLabelValue} {
      font-size: 2rem;
      font-weight: 800;
      ${interstate.styles}
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      ${altGothic.styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: 0.2;
      }
    }
  `
}

/**
 * Font Group: Inter
 *
 */
export const interAllFontGroup = (theme) => {
  const [interBold, interSemiBold] = loadGoogleFonts(['Inter:700', 'Inter:600'])
  return css`
    ${PrintTitle} {
      font-size: 2.8rem;
      text-transform: uppercase;
      ${interBold.styles}
    }

    ${PrintSecondaryTitle} {
      font-size: 1.9rem;
      text-transform: uppercase;
      ${interSemiBold.styles}
    }

    ${PrintLabelValue} {
      font-size: 2.1rem;
      text-transform: initial;
      ${interBold.styles}
    }

    ${PrintLabelTitle} {
      font-size: 1.3rem;
      text-transform: uppercase;
      ${interSemiBold.styles}
    }
  `
}
