import React from 'react'
import styled from 'styled-components'

import { colours, fonts } from '../../theme'
import { media } from '../../styles'
import * as routes from '../../routes'
import config from '../../config'

import { CompatibleWithStrava, MapboxLogo } from '../Logo'
import SiteContainer from '../SiteContainer'
import { Text, SmallText } from '../Text'
import { Link, NavLink, IconNavLink } from '../Link'
import { Icon } from '../Icon'
import Emoji from '../Emoji'

const year = new Date().getFullYear()

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const ShopNavLink = NavLink.withComponent(Link)

const PaymentProviders = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 1rem;

  .icon-paymentVisa {
    width: 4.2rem;
  }
  .icon-paymentStripe {
    width: 6.5rem;
  }
  .icon-paymentMaestro {
    width: 5.2rem;
    margin-top: 0.6rem;
  }
  .icon-paymentMastercard {
    width: 5.3rem;
  }
`

export const SiteFooter = (props) => {
  const classNames = props.className
  const isCreateActive = (match, location) =>
    !!match || location.pathname.includes(routes.PREVIEW)
  return (
    <footer className={classNames}>
      <SiteContainer>
        <div className="left">
          <Text>
            If you have any questions, please get in touch{' '}
            <Emoji emoji="thumbsUp" />
            <br />
            <Link to={`mailto:${config.email.contact}`}>
              {config.email.contact}
            </Link>
          </Text>
          <PaymentProviders>
            <Icon icon="paymentMaestro" />
            <Icon icon="paymentMastercard" />
            <Icon icon="paymentVisa" />
            <Icon icon="paymentStripe" />
          </PaymentProviders>
          <Logos>
            <CompatibleWithStrava />
            <MapboxLogo />
          </Logos>
          <SmallText light>
            <Link to={routes.TERMS_AND_CONDITIONS}>Terms & Conditions</Link> •{' '}
            <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link> •{' '}
            <Link to={routes.COOKIE_POLICY}>Cookie Policy</Link> •{' '}
            <Link to={routes.FAQ_SHIPPING}>Shipping</Link> • All content ©
            Copyright {year}
            <br />
            Created by <Link to="https://brewerlogic.com">
              Adam Brewer
            </Link> & <Link to="https://studiobrewer.com">Jake Brewer</Link>
          </SmallText>
        </div>
        <div className="right">
          <ul>
            <li>
              <NavLink to={routes.CREATE} isActive={isCreateActive}>
                Create
              </NavLink>
            </li>
            {/* <li>
              <ShopNavLink to={routes.SHOP}>Art Prints</ShopNavLink>
            </li> */}
            {/* <li>
              <ShopNavLink to={routes.EVENTS}>Events</ShopNavLink>
            </li> */}
            <li>
              <NavLink to={routes.ABOUT}>About</NavLink>
            </li>
            <li>
              <NavLink to={routes.FAQ}>Help</NavLink>
            </li>
            <li>
              <IconNavLink to={routes.INSTAGRAM}>
                <Icon icon="instagram" />
              </IconNavLink>
              <IconNavLink to={routes.FACEBOOK}>
                <Icon icon="facebook" />
              </IconNavLink>
              <IconNavLink to={routes.TWITTER}>
                <Icon icon="twitter" />
              </IconNavLink>
            </li>
          </ul>
        </div>
      </SiteContainer>
    </footer>
  )
}

SiteFooter.propTypes = {}
SiteFooter.defaultProps = {}

const StyledSiteFooter = styled(SiteFooter)`
  background-color: ${colours.grey07};
  font-size: ${fonts.size03};
  line-height: ${fonts.lineHeight02};

  ${SiteContainer} {
    padding-top: 14.5rem;
    padding-bottom: 7rem;
    display: flex;
    flex-direction: row;

    .text {
      color: ${colours.grey03};
    }
  }

  .left {
    flex: 0 1 64%;
  }

  .right {
    flex: 0 1 36%;
  }

  .right {
    ${Link} {
      text-decoration: none;
    }

    li:last-of-type {
      margin-left: -2px;
      display: flex;
    }
  }

  ${Text} {
    font-size: inherit;
  }

  ${MapboxLogo} {
    max-width: 12rem;
    margin-left: 3rem;
    position: relative;
    top: 0.5rem;
  }

  ${CompatibleWithStrava} {
    max-width: 10rem;
  }

  ${PaymentProviders} {
    margin-top: 5rem;
    margin-left: -0.5rem;
  }

  ${Logos} {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }

  ${NavLink},
  ${ShopNavLink} {
    font-size: inherit;
  }

  ul,
  ${Text} {
    ${Link} {
      font-weight: ${fonts.weightMedium};
      color: ${colours.primary};
      &:hover {
        color: ${colours.secondary};
      }
    }
    ${ShopNavLink} {
      &:hover,
      &:focus {
        color: ${colours.primary};
      }
    }
  }

  ${SmallText} {
    margin-top: 1.5rem;
    line-height: 2;
  }

  ${IconNavLink} {
    margin-top: 1rem;
    margin-right: 0.6rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.footer`
    ul {
      text-align: right;
    }
    .left {
      flex: 1 1 auto;
      padding-right: 4rem;
    }
    .right {
      flex: 0 1 auto;
    }
  `}

  ${media.nav`

    ${SiteContainer} {
      padding-top: 6rem;
      padding-bottom: 3rem;
      flex-direction: column-reverse;
    }
    .right {
      margin-bottom: 4rem;
    }
    .left {
      padding-right: 0;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        margin-right: 1em;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    ${NavLink},
    ${ShopNavLink},
    ${Text} {
      font-size: ${fonts.size02};
    }

    ${IconNavLink} {
      margin-top: 8px;
      svg {
        width: 2.4rem;
      }
    }

    ${Logos} {
      margin-top: 3rem;
      margin-bottom: 1.5rem
    }

    ${SmallText} {
      margin-top: 0.6rem;
    }

  `}
`

export default StyledSiteFooter
