import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as routes from '../../routes'

import { media } from '../../styles'
import { colours, dimensions, fonts } from '../../theme'
import TransitionGroup from '../TransitionGroup'
import { PlainButton } from '../Button'
import { Icon } from '../Icon'
import { Text } from '../Text'
import Link from '../Link'

const ToasterTransition = styled(TransitionGroup)`
  .transition-appear.transition-appear-active,
  .transition-enter.transition-enter-active {
    opacity: 1;
    max-height: 20rem;
  }

  .transition-leave {
    max-height: 20rem;
  }
`

export const stravaBemcMessage = () => (
  <span>
    This voucher is for Strava Premium subscribers only. <br />
    Please visit <Link to={routes.STRAVA_BEMC_2024}>this page</Link> for more
    information.
  </span>
)

/**
 * Toaster Component
 *
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledToaster = (props) => (
  <div className={props.className}>
    <ToasterTransition element="ul">
      {React.Children.map(props.children, (toast) =>
        toast ? <li key={`${toast.key}_li`}>{toast}</li> : null,
      )}
    </ToasterTransition>
  </div>
)

UnstyledToaster.propTypes = {}
UnstyledToaster.defaultProps = {}

const Toaster = styled(UnstyledToaster)`
  li:not(:last-of-type) {
    border-bottom: 2px solid ${colours.white};
  }
`

/**
 * Toast Component
 *
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledToast = (props) => (
  <aside id={props.id} className={props.className}>
    <div className="inner">
      <Text>
        <span className="text-inner">{props.message}</span>
      </Text>
      <PlainButton onClick={props.onClose}>
        <Icon icon="close" />
      </PlainButton>
    </div>
  </aside>
)

UnstyledToast.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.node,
  variant: PropTypes.string,
  onClose: PropTypes.func,
}

UnstyledToast.defaultProps = {
  id: undefined,
  message: undefined,
  variant: undefined,
  onClose: () => {},
}

const Toast = styled(UnstyledToast)`
  background: ${({ variant }) =>
    variant === 'primary' ? colours.primary : colours.secondary}
  color: ${colours.white};

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: ${dimensions.buttonHeight};
    padding: 3rem 3rem;
    text-align: center;
  }

  ${Text} {
    padding: 3rem 0;
    flex: 1 1 auto;
    margin: 0 1em 0;
  }

  .text-inner {
    display: inline-block;
    max-width: 32em;
  }

  ${PlainButton} {
    width: 1.8rem;
    height: 1.8rem;
    align-self: flex-start;
    flex: 0 0 auto;
    color: ${colours.white};
    background: transparent;

    &:hover {
      color: ${colours.primary};
    }
  }

  /* MEDIA STYLES */
  ${media.footer`
    .inner {
      padding: 2rem 3rem;
    }
    ${Text} {
      padding: 1rem 0;
      font-size: ${fonts.size02};
    }
  `}

  ${media.nav`
    .inner {
      text-align: left;
    }
    ${PlainButton} {
      align-self: center;
    }
    ${Text} {
      padding: 0;
      margin: 0 2em 0 0;
      font-size: ${fonts.size09};
    }
  `}
`

export { Toast, Toaster, UnstyledToast, UnstyledToaster }
export default Toaster
