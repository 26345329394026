import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravabemc2024light',
  name: 'Strava BEMC 2024: Light',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm0z9nrf401cm01qkge9g6bza',
  cssStyles,
  palette,
}
