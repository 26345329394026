import React, { useState } from 'react'
import styled from 'styled-components'
import ImageFooter from '../../images/strava-bemc-footer.jpg'

const Container = styled.div`
  width: 100%;
  max-width: 150rem;
  margin: auto;
  padding-right: 6rem;
  padding-left: 90px;
  margin-bottom: 8rem;
  padding-bottom: 8rem;
  hr {
    border: none;
    height: 1px;
    background-color: #d8d8d8;
    margin-bottom: 8rem;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
  h2 {
    margin-top: 2rem;
    font-size: 50px;
    line-height: 60px;
    max-width: 1080px;
    margin-bottom: 4rem;
    padding-left: 6rem;
    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 0;
      padding-left: 0;
    }
  }
`

const Footer = styled.div`
  margin-top: 10rem;
  @media (max-width: 768px) {
    margin-top: 4rem;
  }
`

const QuestionContainer = styled.li`
  padding-left: 6rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 34px;
  line-height: 44px;
  @media (max-width: 768px) {
    padding-left: 0;
    font-size: 20px;
    line-height: 26px;
  }
  &:nth-of-type(even) {
    background-color: #f7f7f7;
  }
`

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  svg {
    margin-right: 70px;
    transition: all 0.25s ease-in-out;
    @media (max-width: 768px) {
      margin-right: 0;
      max-width: 12px;
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`

const Answer = styled.div`
  font-weight: 300;
  transition: all 0.25s ease-in-out;
  max-width: 1068px;
  &.closed {
    overflow-y: hidden;
    max-height: 0px;
    height: 100%;
    opacity: 0;
  }
  &.open {
    overflow-y: visible;
    max-height: none;
    height: auto;
    padding: 50px;
    opacity: 1;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
`

const QuestionAnswer = ({ question, answer, setOpen, open }) => {
  return (
    <QuestionContainer
      onClick={() => setOpen(open === question ? false : question)}
    >
      <Question className={open === question ? 'open' : 'closed'}>
        {question}
        <svg
          width="27"
          height="16"
          viewBox="0 0 27 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5 16L27 2.86102e-06L1.43055e-06 5.53062e-07L13.5 16Z"
            fill="#FF3801"
          />
        </svg>
      </Question>
      <Answer className={open === question ? 'open' : 'closed'}>
        {answer}
      </Answer>
    </QuestionContainer>
  )
}

const StravaBEMCMicrositeFAQ = () => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <hr />
      <div className="flex">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Do you offer tracking?"
            answer="We have tracking for the majority of orders which are sent out via email once dispatched, however some locations we might not be able to do so due to cost and logistics."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="What paper do you use?"
            answer="We use 200gsm Enhanced Matte Art paper, which is a premium, heavyweight and slightly textured fine art paper with a smooth, clean finish."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="My Strava activities aren't loading"
            answer="Make sure you the Strava activities are yours, they are public and that you've ticked 'View data about your activities' when Strava asks you for access."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Can I use miles instead of kilometers?"
            answer="We’re currently looking into this but for now, we suggest converting your values manually and entering them into the relevant text areas within the print. Regardless, data from Strava or GPX files will always be measured in metric units and we’d recommend that you double-check the values are correct."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Does it come framed?"
            answer="Of course! Please select which framed option you'd like when creating your print."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            id="returns-refunds"
            question="Do you offer returns or refunds?"
            answer="Since a print is highly personalised we can’t offer returns, but if you're really unsatisfied please let us know what it is you’re not happy with and we can either sort it out or offer a full refund."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Your app doesn't work in my phone or browser"
            answer="We support as many devices as possible and are constantly testing across many different screen sizes, aspect ratios and browsers. If you do have any issues or something doesn’t seem quite right, please let us know and/or ensure you’re using the latest versions of either Firefox, Google Chrome or Microsoft Edge."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Can I just purchase a map without an activity/route?"
            answer="Absolutely! You could make a print of any area such as your local neighbourhood or a memorable, special place. If you don’t want any text labels, just delete them from the text fields in the ‘labels’ section and they'll disappear from the print."
          />
        </ul>
      </div>
      <Footer>
        <img src={ImageFooter} alt="" />
      </Footer>
    </Container>
  )
}

export default StravaBEMCMicrositeFAQ
