import { css } from 'styled-components'

import badge from './badge.png'
import MapboxMap from '../../../../../components/MapboxMap'
import { badgeStyles } from '../styles-common'
import {
  LogoCredits,
  PoweredByStrava,
  StravaAdidas,
} from '../../../../../components/Logo'
import { Attribution } from '../../../../../components/Attribution'
import PrintLabel from '../../../../../components/PrintLabel'
import { fontGroup } from '../../../styles'

export default (theme) => css`
  ${fontGroup('montserratAll')(theme)}

  ${LogoCredits},
  ${PoweredByStrava},
  ${StravaAdidas} {
    opacity: 1;
    color: #182f6a;
  }

  ${Attribution} {
    opacity: 1;
    color: #182f6a;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #182f6a;
      opacity: 1;
    }
  }

  ${badgeStyles}

  ${MapboxMap}:after {
    background-image: url(${badge});
  }
`
