import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants'

import darkThumb from './event-thumbs/strava-bemc-2024-dark.png'
import lightThumb from './event-thumbs/strava-bemc-2024-light.png'
import orangeThumb from './event-thumbs/strava-bemc-2024-orange.png'
import berlinThumb from './event-thumbs/strava-bemc-2024-berlin.png'
import chicagoThumb from './event-thumbs/strava-bemc-2024-chicago.png'
import newyorkThumb from './event-thumbs/strava-bemc-2024-newyork.png'

export const themes = [
  {
    themeName: 'stravabemc2024light',
    image: lightThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'STRAVA Light Mode',
    subtitle: '',
  },
  {
    themeName: 'stravabemc2024dark',
    image: darkThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'STRAVA Dark Mode',
    subtitle: '',
  },
  {
    themeName: 'stravabemc2024orange',
    image: orangeThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'STRAVA Orange',
    subtitle: '',
  },
  {
    themeName: 'stravabemc2024berlin',
    image: berlinThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'Berlin',
  },
  {
    themeName: 'stravabemc2024chicago',
    image: chicagoThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'Chicago',
  },
  {
    themeName: 'stravabemc2024newyork',
    image: newyorkThumb,
    orientation: ORIENTATION_PORTRAIT,
    title: 'New York',
  },
]

const themeNames = themes.map((theme) => theme.themeName)

export default {
  logo: null,
  badge: null,
  uri: 'stravabemc',
  link: 'https://www.strava.com/',
  linkText: 'strava.com',
  blurb: null,
  title: 'Strava BEMC',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: null,
  metaDescription: null,
  metaImage: null,
  themes,
  themeNames,
}
