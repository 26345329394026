import React from 'react'
import styled from 'styled-components'
import Hero from '../../images/bemc-strava.webp'
import HeroMobile from '../../images/strava-bemc-mobile.jpg'
import Badges from '../../images/bemc-strava-badges.webp'

const Container = styled.div`
    width: 100%;
    max-width: 150rem;
    margin: auto;
    padding-left: 6rem;
    padding-right: 6rem;
    margin-bottom: 8rem;
    padding-bottom: 8rem;
    @media(max-width: 768px) {
      margin-top: 0;
      margin-bottom: 2rem;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
    img.hero {
      width: 100%;
    }
    img.badges {
      width: 100%;
      max-width: 260px;
      margin-top: 4rem;
    }
    h2 {
      margin-top: 2rem;
      font-size: 50px;
      line-height: 60px;
      max-width: 1080px;
      margin-bottom: 4rem;
      @media(max-width: 768px) {
        font-size: 34px;
        line-height: 40px;
      }
    }
    ul {
      counter-reset: item;
      padding-left: 0;
      li {
        list-style: outside;
        margin-left: 1em;
        list-style-type: decimal;
        margin-bottom: 2rem;
        a {
          color: #ff3801;
          text-decoration: none;
        }
      }
    }
    p, li {
      font-size: 34px;
      line-height: 44px;
      max-width: 900px;
      @media(max-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    a.button {
      display: block;
      text-decoration: none;
      background-color: #FF3801;
      color: #fff;
      border-radius: 12px;
      border: none;
      font-size: 26px;
      line-height: 50px;
      outline: 0;
      padding: 1.5rem 8rem;
      text-transform: uppercase;
      @media(max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
      }
    }
    div.flex {
      display: flex;
      margin-top: 4rem;
      column-gap: 20px;
      padding-left: 90px;
      @media(max-width: 768px) {
        margin-top: 0;
        padding-left: 0;
        flex-direction: column;
      }
    }
`

const Desktop = styled.div`
  display: block;
  @media(max-width: 768px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
`

const StravaBEMCMicrosite = () => {
  return (
    <Container>
      <Desktop>
        <img className="hero" src={Hero} alt="" />
      </Desktop>
      <Mobile>
        <img className="hero" src={HeroMobile} alt="" />
      </Mobile>
      <div className="flex">
        <div>
          <h2>You’ve completed the Best Efforts Marathon Challenge, powered by adidas!</h2>
          <p>Now, it’s time to celebrate with your personalized print. Whether you earned a free print or got a 15% discount, the next part is simple:</p>
          <ul>
            <li>Check your email inbox for details on how to redeem your print and your unique redemption code.</li>
            <li>Make sure you're logged in at <a href="https://www.strava.com" rel="nofollow noopener noreferrer">strava.com</a> to begin the redemption process.</li>
            <li>Click on your favorite theme below to get started.</li>
            <li>Follow the checkout steps and your print will be on the way.</li>
          </ul>
        </div>
        <Desktop><img className="badges" src={Badges} alt="" /></Desktop>
      </div>
    </Container>
  )
}

export default StravaBEMCMicrosite
