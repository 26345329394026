import { css } from 'styled-components'

import badge from './badge.png'
import MapboxMap from '../../../../../components/MapboxMap'
import commonStyles from '../styles-common'

export default () => css`
  ${commonStyles}

  ${MapboxMap}:after {
    background-image: url(${badge});
  }
`
