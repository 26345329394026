/* istanbul ignore file */
/* eslint-disable no-restricted-globals */

const { REACT_APP_DEBUG_KONAMI, REACT_APP_DEBUG_GODMODE } = process.env

// iddqd
/* prettier-ignore */
const godModeFn = (f, a) => { document.onkeyup = function (e) { /611141$/.test(a += '' + ((e || self.event).keyCode - 67)) && f() } }

export const godMode = (cb) =>
  REACT_APP_DEBUG_GODMODE ? godModeFn(cb) : () => {}
