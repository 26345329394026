import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravabemc2024dark',
  name: 'Strava BEMC 2024: Dark',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm0z05z4n01a101o3fqev68x2',
  cssStyles,
  palette,
}
