import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'

import HocComponent from '../HocComponent'

import * as Actions from './actions'
import * as Selectors from './selectors'

export const MakeWithOrdersHoc = (WrappedComponent) =>
  new HocComponent(WrappedComponent, 'withOrdersHoc')

export const WithOrdersHoc = () => (WrappedComponent) => {
  const WithOrders = MakeWithOrdersHoc(WrappedComponent)

  const mapStateToProps = createStructuredSelector({
    orderInProgress: Selectors.orderInProgressSelector,
    stripeActionRequired: Selectors.stripeActionRequiredSelector,
    intentSecret: Selectors.intentSecretSelector,
  })

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        setOrderInProgress: Actions.setOrderInProgress,
        createZeroCostOrder: Actions.createZeroCostOrder,
        createStripeOrder: Actions.createStripeOrder,
        confirmStripeOrder: Actions.confirmStripeOrder,
        createPaypalOrder: Actions.createPaypalOrder,
        getPrintOrder: Actions.getPrintOrder,
      },
      dispatch,
    )

  const withConnect = connect(mapStateToProps, mapDispatchToProps)

  const composed = compose(withRouter, withConnect)(WithOrders)

  return composed
}

export default WithOrdersHoc
