/**
 * Takes an object of form data and maps it to Stripe's format
 * @param {object} formData
 *
 */
export const makeStripeBillingDetails = (formData = {}) => {
  return {
    name: formData.name,
    email: formData.email,
    phone: formData.phone_mobile,
    address: {
      city: formData.address_city,
      country: formData.address_country,
      line1: formData.address_line1,
      line2: formData.address_line2,
      postal_code: formData.address_zip,
      state: formData.address_state,
    },
  }
}

export const makeStripeBillingDetailMock = (formData = {}) => {
  return {
    name: 'Adam Smith',
    email: 'adam@papertrails.io',
    phone: '+447700900000',
    address: {
      city: 'London',
      country: 'UK',
      line1: '1 Hackney Road',
      line2: '',
      postal_code: 'E1 6RF',
      state: 'London',
    },
  }
}
