import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _get from 'lodash.get'

import { dimensions, colours } from '../../theme'
import * as printUtils from '../../utils/print'

import { MapboxMap, propTypes as mapPropTypes } from '../MapboxMap'
import { PrintDetails } from '../PrintDetails'
import ElevationProfile from '../ElevationProfile'
import { PoweredByStrava, LogoCredits, StravaAdidas } from '../Logo'
import { LAYOUT_OPTIONS, ORIENTATION_OPTIONS } from '../../hocs/WithPrint/model'
import {
  generalThemeStyles,
  specificThemeStyles,
} from '../../hocs/WithThemes/styles'

const padding = dimensions.printPadding

const STRAVA_BEMC_2024_THEMES = [
  'stravabemc2024light',
  'stravabemc2024dark',
  'stravabemc2024orange',
  'stravabemc2024berlin',
  'stravabemc2024chicago',
  'stravabemc2024newyork',
]

const UnstyledPrint = ({ className, style, children, strava, theme }) => (
  <div className={className} style={style}>
    {children}
    <LogoCredits />
    {STRAVA_BEMC_2024_THEMES.includes(theme.themeName) ? (
      <StravaAdidas />
    ) : (
      strava && <PoweredByStrava />
    )}
  </div>
)

UnstyledPrint.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  layout: PropTypes.oneOf(LAYOUT_OPTIONS).isRequired,
  orientation: PropTypes.oneOf(ORIENTATION_OPTIONS).isRequired,
  padding: PropTypes.bool.isRequired,
  porthole: PropTypes.bool.isRequired,
  mapStyles: mapPropTypes.mapStyles.isRequired,
  hasDetails: PropTypes.bool.isRequired,
  hasElevationProfile: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    themeName: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }).isRequired,
  /* eslint-enable */
  strava: PropTypes.bool.isRequired,
}

UnstyledPrint.defaultProps = {}

const logoCreditsPosition = (p) => {
  if (isVertical(p) && isTop(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 100% 100%;
      right: ${!hasDetails(p) && hasPadding(p) ? '0.9rem' : '2.8rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 12.3rem;'
        : 'top: 1.9rem;'}
    `

  if (isVertical(p) && isBottom(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 100% 100%;
      right: ${!hasDetails(p) && hasPadding(p) ? '0.9rem' : '2.8rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 1.3rem;'
        : 'bottom: 12.9rem;'}
    `

  if (isHorizontal(p) && isTop(p))
    return css`
      left: 3rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 2.2rem;'
        : `top: ${!hasDetails(p) && hasPadding(p) ? '1.1rem' : '2.5rem'};`}
    `

  if (isHorizontal(p) && isBottom(p))
    return css`
      left: 3rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 2.5rem;'
        : `bottom: ${!hasDetails(p) && hasPadding(p) ? '0.7rem' : '2.1rem'};`}
    `

  return ''
}

const poweredByStravaPosition = (p) => {
  if (isVertical(p) && isTop(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 0 0;
      left: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '3rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 1.2rem;'
        : 'top: 13.4rem;'}
    `

  if (isVertical(p) && isBottom(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 0 0;
      left: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '3rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 12.8rem;'
        : 'bottom: 1.7rem;'}
    `

  if (isHorizontal(p) && isTop(p))
    return css`
      right: 3.1rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 2.4rem;'
        : `top: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '2.4rem'};`}
    `

  if (isHorizontal(p) && isBottom(p))
    return css`
      right: 3.1rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 2.4rem;'
        : `bottom: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '2.4rem'};`}
    `

  return ''
}

const poweredByStravaAdidasPosition = (p) => {
  if (isVertical(p) && isTop(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 0 0;
      left: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '3rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 1.2rem;'
        : 'top: 20rem;'}
    `

  if (isVertical(p) && isBottom(p))
    return css`
      transform: rotateZ(-90deg);
      transform-origin: 0 0;
      left: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '3rem'};

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 12.8rem;'
        : 'bottom: 0.4rem;'}
    `

  if (isHorizontal(p) && isTop(p))
    return css`
      right: 3.1rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'bottom: 2.4rem;'
        : `top: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '1.8rem'};`}
    `

  if (isHorizontal(p) && isBottom(p))
    return css`
      right: 3.1rem;

      ${hasElevationProfile(p) && !hasDetails(p) && !hasPadding(p)
        ? 'top: 2.4rem;'
        : `bottom: ${!hasDetails(p) && hasPadding(p) ? '1rem' : '2rem'};`}
    `

  return ''
}

const ElevationProfilePadding = (p) => {
  if (hasPadding(p) && isBottom(p))
    return css`
      padding: ${hasDetails(p) ? padding : 0} ${padding}
        ${hasDetails(p) ? 0 : padding};
    `

  if (hasPadding(p) && isTop(p))
    return css`
      padding: ${hasDetails(p) ? 0 : padding} ${padding}
        ${hasDetails(p) ? padding : 0};
    `

  if (!hasPadding(p) && isBottom(p))
    return css`
      padding: ${padding} 0 0;
    `

  if (!hasPadding(p) && isTop(p))
    return css`
      padding: ${hasDetails(p) ? 0 : padding} 0 ${hasDetails(p) ? padding : 0};
    `

  return ''
}

const Print = styled(UnstyledPrint)`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: ${(p) => getLayoutOrder(p)};

  ${(p) => {
    const productAttributes = _get(p, 'productAttributes')
    if (productAttributes && productAttributes.color) {
      return css`
        border: 4rem solid ${productAttributes.color.replace(/black/i, '#222')};
        box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.2);
      `
    }
    return ''
  }}

  ${LogoCredits} {
    max-width: 10rem;
    ${(p) => logoCreditsPosition(p)}
  }

  ${PoweredByStrava} {
    max-width: 10.4rem;
    ${(p) => poweredByStravaPosition(p)}
  }

  ${StravaAdidas} {
    max-width: 16.9rem;
    ${(p) => poweredByStravaAdidasPosition(p)}
  }

  ${LogoCredits},
  ${PoweredByStrava},
  ${StravaAdidas} {
    position: absolute;
    opacity: 0.6;
    pointer-events: none;
    display: inline-flex;
    opacity: 0.5;
    color: ${colours.black};
  }

  ${MapboxMap} {
    flex: 1 0 0;
    height: auto;
    overflow: visible;
    position: relative;
    padding: ${(p) => getMapboxPadding(p)};
  }

  /* TODO: .mapbox-wrapper */
  .mapboxgl-map {
    ${(p) =>
      hasPadding(p) &&
      css`
        /* WITH PADDING */
        border-radius: ${dimensions.borderRadius};
      `}
  }

  ${(props) =>
    isPorthole(props) &&
    css`
      ${MapboxMap} {
        display: flex;
        align-items: center;
        padding: ${padding};

        .mapboxgl-map {
          height: 0;
          padding-bottom: 100%;
        }
        .mapboxgl-canvas {
          border-radius: 50%;
          border: 2px solid currentColor;
        }
      }
    `}

  .mapboxgl-canvas {
    /* removes the background bleed between the canvas and the border */
    background-color: ${(p) => getCanvasBackgroundColor(p)};
  }

  ${(p) =>
    isBottom(p)
      ? css`
          .mapboxgl-ctrl-bottom-left {
            /* defaults to top left */
            bottom: auto;
            top: 0;

            .mapboxgl-ctrl {
              ${hasPadding(p)
                ? css`
                    margin: 10px 10px 10px 10px;
                  `
                : css`
                    margin: 22px 10px 10px 60px;
                  `}
            }
          }
        `
      : css`
          .mapboxgl-ctrl-bottom-left {
            .mapboxgl-ctrl {
              ${hasPadding(p)
                ? css`
                    margin: 10px 10px 10px 10px;
                  `
                : css`
                    margin: 0 0 24px 60px;
                  `}
            }
          }
        `}

  ${PrintDetails} {
    padding: ${padding};
  }

  ${ElevationProfile} {
    ${ElevationProfilePadding}
  }

  ${generalThemeStyles}
  ${specificThemeStyles}
`

export const getCanvasBackgroundColor = (props) => {
  if (isPorthole(props)) {
    const layers = _get(props, 'mapStyles.layers', [])
    const waterLayer = layers.find(({ id }) => /water$/i.test(id))
    return _get(waterLayer, 'paint.fill-color', 'transparent')
  }
  return 'transparent'
}

export const getLayoutOrder = (props) =>
  printUtils.isLayoutTop(props.layout) ? 'column-reverse' : 'column'

export const getMapboxPadding = (props) => {
  const pad = padding
  if (isBottom(props)) {
    if (hasPadding(props) && !hasDetails(props)) return pad
    if (hasPadding(props) && hasDetails(props)) return `${pad} ${pad} 0`
  } else {
    if (hasPadding(props) && !hasDetails(props)) return pad
    if (hasPadding(props) && hasDetails(props)) return `0 ${pad} ${pad}`
  }
  return 0
}

export const isLandscape = (props) =>
  printUtils.isOrientationLandscape(props.orientation)
export const isPortrait = (props) =>
  printUtils.isOrientationPortrait(props.orientation)
export const isVertical = (props) => printUtils.isLayoutVertical(props.layout)
export const isHorizontal = (props) =>
  printUtils.isLayoutHorizontal(props.layout)
export const isBottom = (props) => printUtils.isLayoutBottom(props.layout)
export const isTop = (props) => printUtils.isLayoutTop(props.layout)
export const hasPadding = (props) => props.padding
export const hasDetails = (props) => props.hasDetails
export const isPorthole = (props) => props.porthole
export const usingStrava = (props) => props.strava
export const hasElevationProfile = (props) => props.hasElevationProfile

export { Print, UnstyledPrint }
export default Print
