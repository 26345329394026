import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravabemc2024chicago',
  name: 'Strava BEMC 2024: Chicago',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm11s77c501h501qk3hn78w2m',
  cssStyles,
  palette,
}
