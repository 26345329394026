import { css } from 'styled-components'

import badge from './badge.png'
import MapboxMap from '../../../../../components/MapboxMap'
import commonStyles from '../styles-common'
import {
  LogoCredits,
  PoweredByStrava,
  StravaAdidas,
} from '../../../../../components/Logo'
import { Attribution } from '../../../../../components/Attribution'

export default () => css`
  ${commonStyles}

  ${LogoCredits},
  ${PoweredByStrava},
  ${StravaAdidas} {
    color: #ffffff;
  }

  ${Attribution} {
    color: #ffffff;
  }

  ${MapboxMap}:after {
    background-image: url(${badge});
  }
`
