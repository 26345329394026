import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import { media, makeMedia } from '../../styles'
import { colours, fonts } from '../../theme'
import {
  ORIENTATION_LANDSCAPE,
  ORIENTATION_PORTRAIT,
} from '../../hocs/WithPrint/constants'

import {
  ThemeCard,
  ThemeCardSubtitle,
  ThemeCardTitle,
  ThemeCardFooter,
} from '../ThemeCard'
import { Link } from '../Link'

export const ThemesListItemOuter = styled.div``
export const ThemesListItemInner = styled.div``
export const ThemeCardWrapper = styled.div``

export const ThemesListItem = styled((props) => (
  <li className={classnames(props.orientation, props.className)}>
    <Link to={props.to} onClick={props.onClick}>
      <ThemesListItemOuter>
        <ThemesListItemInner>
          <ThemeCardWrapper>
            <ThemeCard
              img={props.image}
              title={props.title}
              orientation={props.orientation}
            />
          </ThemeCardWrapper>
          <ThemeCardFooter>
            <ThemeCardTitle>{props.title}</ThemeCardTitle>
            <ThemeCardSubtitle>{props.subtitle}</ThemeCardSubtitle>
          </ThemeCardFooter>
        </ThemesListItemInner>
      </ThemesListItemOuter>
    </Link>
  </li>
))`
  flex: 1 1 auto;
  max-width: 40rem;

  &.portrait {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  ${Link} {
    height: 100%;
    display: block;
    text-decoration: none;

    ${ThemeCardTitle} {
      color: ${colours.primary};
      text-decoration: underline;
    }

    &:hover {
      ${ThemeCardTitle} {
        color: ${colours.primary} !important;
        text-decoration: none;
      }
      ${ThemeCard} {
        transform: scale(0.99);

        &:after {
          opacity: 0.75;
        }
      }
    }
  }

  ${ThemesListItemOuter} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  ${ThemesListItemInner} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${ThemeCardFooter} {
    margin-top: 4rem;
  }

  ${ThemeCardTitle} {
    font-weight: ${fonts.weightMedium};
    margin: auto 0.2em;
  }

  &.landscape ${ThemeCardWrapper} {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  ${ThemeCard} {
    position: relative;
    transform: scale(1);
    transition: 0.2s ease;
    backface-visibility: hidden;
    margin: auto;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colours.primary};
      transform: scale(1.01);
      opacity: 0;
      pointer-events: none;
      transition: 0.3s ease opacity;
    }
  }

  ${makeMedia(1400)`
    ${ThemeCardTitle} {
      font-size: ${fonts.size07};
    }
  `}

  ${media.footer`

    ${ThemeCardTitle} {
      font-size: ${fonts.size03};
    }
  `}
`

ThemesListItem.propTypes = {
  to: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  orientation: PropTypes.oneOf([ORIENTATION_PORTRAIT, ORIENTATION_LANDSCAPE]),
  onClick: PropTypes.func,
}

ThemesListItem.defaultProps = {
  orientation: ORIENTATION_PORTRAIT,
  onClick: () => {},
}

export const ThemesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  grid-column-gap: 5rem;
  grid-row-gap: 7rem;
  text-align: center;
  margin-bottom: 12rem;

  ${makeMedia(1400)`
    .portrait {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  `}

  ${media.footer`

    .portrait {
      padding-left: 0;
      padding-right: 0;
    }
  `}

  ${media.nav`
    margin-bottom: 6rem;
  `}
`
