import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'stravabemc2024berlin',
  name: 'Strava BEMC 2024: Berlin',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cm11tod6b01dr01pb8yy35ev9',
  cssStyles,
  palette,
}
